import NavFactory from "@/client/extensions/helpers/navFactory.js";

export default async ({ asyncOps, store }) => {
  // older code - we did not have navFactory then
  let adminNavItemFactoryFunction;
  let navItems;
  try {
    adminNavItemFactoryFunction =
      await require("@/client/assets/static/core/nav/adminSideNavFactory.js");
    navItems = await adminNavItemFactoryFunction.default({
      asyncOps,
      store,
    });
  } catch (e) {
    return {};
  }

  let nav = new NavFactory(navItems);

  let lpTitleItem = {
    action: {
      type: "title",
    },
    label: "ns.admin.sideNav.nimbusContentTitle",
  };

  let campaignList = {
    icon: "info",
    label: "ns.admin.sideNav.campaign",
    permissions: {
      method: "canAnySoft",
      rules: [
        {
          asset: "campaign",
          actions: ["update"],
          level: "some",
        },
        {
          asset: "campaign",
          actions: ["create"],
          level: "some",
        },
        {
          asset: "campaign",
          actions: ["list"],
          level: "some",
        },
      ],
    },
    acl: {
      assetType: "campaign",
      actions: ["list"],
    },
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-campaign",
        },
      },
    },
  };
  let leadList = {
    icon: "arrow-download-alt",
    label: "ns.admin.sideNav.lead",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-lead",
        },
      },
    },
    permissions: {
      method: "canAnySoft",
      rules: [
        {
          asset: "lead",
          actions: ["list"],
          level: "some",
        },

      ],
    },
  };
  let leadMassCreate = {
    icon: "code",
    permissions: {
      method: "hasGroup",
      rules: "superUsers",
    },
    label: "ns.admin.sideNav.leadMassCreate",
    action: {
      type: "routerNavigation",
      data: {
        to: {
          name: "admin-lead-mass-create",
        },
      },
    },
  };
  let mainCategoryItem = nav.getItemChildCopy("entities", "category");

  nav
    .addItemsAfter("dashboard", {
      lpTitleItem,
      campaignList,
      leadList,
      leadMassCreate,
    })
    .addItemAfter("entities", "mainCategoryItem", mainCategoryItem)
    .removeItems(["ecommerceTitle", "vendor", "brand", "product", "order"]);
  return nav.getItems();
};
